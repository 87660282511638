import React from "react";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Container } from "react-bootstrap";
import HeroSection from "./HeroSection";
import PartnerSection from "./PartnerSection";
import "./css/Home.css";
<link rel="stylesheet" href="./css/style.css" />;

const Home = () => {
  return (
    <div>
      <HeroSection />
      <Container>
        <PartnerSection />
      </Container>
      <div className="go-to-page">
        <Nav.Link as={Link} to="/about">
          About→
        </Nav.Link>
      </div>
      <div id="floatingic">
        <div id="floatingic3">
          <a
            target="_blank"
            href="https://wa.me/message/JXCQTMPQ2OQON1"
            style={{ fontSize: 20, color: "green" }}
          >
            <AiOutlineWhatsApp />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;

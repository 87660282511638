import React, { useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./css/Team.css";

import team1 from "../assets/team1.jpg";
import team2 from "../assets/team2.jpg";
import team3 from "../assets/team3.jpg";

import AOS from "aos";
import "aos/dist/aos.css";

import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { AiOutlineWhatsApp } from "react-icons/ai";

const Team = () => {
  const slideshowRef = useRef(null);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    const slides = slideshowRef.current.getElementsByClassName("carousel-item");
    const activeSlide = slideshowRef.current.querySelector(".active");
    const activeIndex = Array.prototype.indexOf.call(slides, activeSlide);
    const nextIndex = (activeIndex + 1) % slides.length;

    activeSlide.classList.remove("active");
    slides[nextIndex].classList.add("active");
  };

  return (
    <div className="team-section">
      <div id="floatingic">
        <div id="floatingic3">
          <a
            target="_blank"
            href="https://wa.me/message/JXCQTMPQ2OQON1"
            style={{ fontSize: 20, color: "green" }}
          >
            <AiOutlineWhatsApp />
          </a>
        </div>
      </div>
      <Container>
        <h1 className="team-title" data-aos="fade-right" data-aos-delay="200">
          Our Team
        </h1>
        <p
          className="team-paragraph"
          style={{ marginRight: 50, marginLeft: 50 }}
          data-aos="fade-up"
          data-aos-delay="400"
        >
          At e-Poultry, we have assembled a talented and passionate team
          dedicated to revolutionizing the poultry farming industry. With
          expertise spanning agriculture, finance, technology, and business
          development, our team is committed to providing robust solutions to
          the challenges faced by value chain players and actors in poultry
          farming.
          <br />
          Together, our team at e-Poultry is driven by a shared passion for
          transforming poultry farming through technology. We are committed to
          empowering value chain players and actors in the industry by providing
          them with innovative and practical solutions that drive
          commercialization, efficiency, and profitability in poultry farming.
        </p>
        <Row>
          <Col md={6} className="slide">
            <div
              className="carousel slide"
              data-bs-ride="carousel"
              data-aos="fade-left"
              ref={slideshowRef}
              style={{ height: 400 }}
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={team1}
                    className="d-block w-100 img-fluid"
                    alt="Slide 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={team2}
                    className="d-block w-100 img-fluid"
                    alt="Slide 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={team3}
                    className="d-block w-100 img-fluid"
                    alt="Slide 3"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="go-to-page">
        <Nav.Link as={Link} to="/" exact>
          Home
        </Nav.Link>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "max-content",
            gap: 10,
          }}
        >
          <Nav.Link as={Link} to="/modules">
            ←Modules
          </Nav.Link>
          <Nav.Link as={Link} to="/contact">
            Contact→
          </Nav.Link>
        </div>
      </div>
    </div>
  );
};

export default Team;

import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./css/Contact.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { AiOutlineWhatsApp } from "react-icons/ai";

const ContactPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(""); // Add phoneNumber state variable
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form validation and submit logic
    if (name && email && phoneNumber && message) {
      // Construct the email URL with the subject and body
      const emailUrl = `mailto:info@epoultry.co.ke ?subject=Contact Form Submission&body=${encodeURIComponent(
        `Name: ${name}%0D%0AEmail: ${email}%0D%0APhone Number: ${phoneNumber}%0D%0AMessage: ${message}`
      )}`;
      // Open the user's default email client
      window.location.href = emailUrl;
      // Reset form fields
      setName("");
      setEmail("");
      setPhoneNumber(""); // Reset phoneNumber field
      setMessage("");
    } else {
      // Display error toast notification
      toast.error("Please fill in all fields.", { autoClose: 3000 });
    }
  };

  return (
    <div className="contact-page">
      <div id="floatingic">
        <div id="floatingic3">
          <a
            target="_blank"
            href="https://wa.me/message/JXCQTMPQ2OQON1"
            style={{ fontSize: 20, color: "green" }}
          >
            <AiOutlineWhatsApp />
          </a>
        </div>
      </div>
      <div className="hero-image">{/* Hero Image */}</div>
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="text-center font-weight-bold">Contact Us</h1>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h2>Chat With Us</h2>
            <p>
              If you have any questions or inquiries, please feel free to reach
              out to us.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  rows="5"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <button type="submit" className="btn btn-danger">
                Send Message
              </button>
            </form>
          </Col>
          <Col md={6}>
            <h2>Our Location</h2>
            <p>
              Physical Address: NEXT TO KISUMU CENTRAL CDF OFFICE, Omollo Agar
              Road
            </p>
            <h4>Telephone/WhatsApp:</h4>
            <p>0742088393</p>
            <h4>Email:</h4>
            <p>info@epoultry.co.ke</p>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.813920999682!2d34.7687319!3d-0.0850854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182aa5bcec2e2be1%3A0xc56f11aa041f0634!2se-POULTRY!5e0!3m2!1sen!2ske!4v1684747787126!5m2!1sen!2ske"
                width="400"
                height="300"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
      <div className="go-to-page">
        <Nav.Link as={Link} to="/" exact>
          Home
        </Nav.Link>
        <Nav.Link as={Link} to="/team">
          ←Gallery
        </Nav.Link>
      </div>
    </div>
  );
};

export default ContactPage;

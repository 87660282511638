import React, { useState } from "react";
import {
  AiOutlineLinkedin,
  AiOutlineFacebook,
  AiOutlineYoutube,
} from "react-icons/ai";
import "./css/Footer.css";
import logo from "../assets/logo.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleSubscribe = (e) => {
    e.preventDefault();
    if (email === "") {
      // Show error toast notification
      toast.error("Email is required!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      // Perform your subscription logic here

      // Show success toast notification
      toast.success("Successfully subscribed!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // Reset the email input field
      setEmail("");
    }
  };

  return (
    <footer
      className="footer bg-black text-white py-5"
      style={{ margin: 0, padding: 0 }}
    >
      <ToastContainer />
      <div
        className="container"
        style={{
          margin: 0,
          padding: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: -40,
        }}
      >
        <div className="row align-items-center">
          <p className="mb-3 mb-md-0" style={{ paddingBottom: 10 }}>
            Subscribe to our newsletter:
          </p>
          <form onSubmit={handleSubscribe} style={{ margin: 0, padding: 0 }}>
            <div className="input-group">
              <input
                type="email"
                className="form-control"
                placeholder="Your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button type="submit" className="btn btn-danger">
                Subscribe
              </button>
            </div>
          </form>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <ul
              className="social-icons d-flex justify-content-center"
              style={{ margin: 0, padding: 0, marginTop: -15 }}
            >
              <li>
                <a href="https://www.linkedin.com/company/epoultry/">
                  <AiOutlineLinkedin />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/profile.php?id=100088593704349&mibextid=ZbWKwL">
                  <AiOutlineFacebook />
                </a>
              </li>

              <li>
                <a href="https://youtube.com/@EPoultryKenya">
                  <AiOutlineYoutube />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <p
              className="text-center mb-0"
              style={{
                margin: 0,
                padding: 0,
                marginTop: -15,
              }}
            >
              &copy; 2023 All rights reserved - ePoultry | Page designed by{" "}
              <a
                href="https://olivebishop.vercel.app/"
                style={{ textDecoration: "none", color: "red" }}
              >
                TRUE GRAPHICS 💕
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

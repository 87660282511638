import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { GiLaurelCrown, GiTargetArrows, GiCrystalGrowth } from "react-icons/gi";
import AboutUsSection from "./AboutUsSection";
import CardComponent from "./Card";
import "./css/About.css";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { AiOutlineWhatsApp } from "react-icons/ai";

const About = () => {
  return (
    <div style={{ margin: 0, padding: 0 }}>
      <div id="floatingic">
        <div id="floatingic3">
          <a
            target="_blank"
            href="https://wa.me/message/JXCQTMPQ2OQON1"
            style={{ fontSize: 20, color: "green" }}
          >
            <AiOutlineWhatsApp />
          </a>
        </div>
      </div>
      <AboutUsSection />
      <Container style={{ marginTop: -100 }}>
        <Row>
          <Col md={3}>
            <CardComponent
              icon={<GiLaurelCrown className="about-us-icon" />}
              title="Vision"
              text="To be a globally recognized poultry management app that digitalizes processes in the poultry value chain."
            />
          </Col>
          <div className="thickline">.</div>
          <Col md={3}>
            <CardComponent
              icon={<GiTargetArrows className="about-us-icon" />}
              title="Mission"
              text="To provide an all-round solution that makes poultry management simpler and more efficient for poultry farmers
              "
            />
          </Col>
          <div className="thickline">.</div>

          <Col md={3}>
            <CardComponent
              icon={<GiCrystalGrowth className="about-us-icon" />}
              title="Core-Values"
              text="Innovation, Customer Satisfaction,Efficiency,Intergrity."
            />
          </Col>
        </Row>
      </Container>
      <div className="go-to-page">
        <Nav.Link as={Link} to="/" exact>
          ←Home
        </Nav.Link>
        <Nav.Link as={Link} to="/product">
          Products→
        </Nav.Link>
      </div>
    </div>
  );
};

export default About;

import React from "react";
import "./css/Product.css";
// Import icons
import {
  FaShoppingBasket,
  FaMedkit,
  FaUtensils,
  FaHome,
  FaKiwiBird,
} from "react-icons/fa";

// Import product images

import product1 from "../assets/product1.jpg";
import product2 from "../assets/product2.jpg";
import product3 from "../assets/product3.jpg";
import product4 from "../assets/product4.jpg";
import product5 from "../assets/poultry3.jpg";

import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { AiOutlineWhatsApp } from "react-icons/ai";

const Product = () => {
  return (
    <div className="product-section">
      <div id="floatingic">
        <div id="floatingic3">
          <a
            target="_blank"
            href="https://wa.me/message/JXCQTMPQ2OQON1"
            style={{ fontSize: 20, color: "green" }}
          >
            <AiOutlineWhatsApp />
          </a>
        </div>
      </div>
      <h2 className="product-title">Our Products</h2>
      <iframe
        title="myFrame"
        width="400"
        height="200"
        id="f-iframe1"
        src="https://www.youtube.com/embed/yuPiUcFGj50"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <div className="row">
        <div className="col-md-4">
          <div className="product-card">
            <img
              src={product1}
              alt="Chicken Feeds"
              className="product-card-image"
            />
            <div className="product-card-content">
              <h3 className="product-card-title">
                <FaShoppingBasket /> Feeders
              </h3>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="product-card">
            <img
              src={product3}
              alt="Medication"
              className="product-card-image"
            />
            <div className="product-card-content">
              <h3 className="product-card-title">
                <FaMedkit /> Medication & Cages
              </h3>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="product-card">
            <img src={product4} alt="Feeders" className="product-card-image" />
            <div className="product-card-content">
              <h3 className="product-card-title">
                <FaUtensils /> Chicken Feeds
              </h3>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="product-card">
            <img src={product2} alt="Drinkers" className="product-card-image" />
            <div className="product-card-content">
              <h3 className="product-card-title">
                <FaHome /> Drinkers
              </h3>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="product-card">
            <img src={product5} alt="Chicks" className="product-card-image" />
            <div className="product-card-content">
              <h3 className="product-card-title">
                <FaKiwiBird /> Chicks
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="go-to-page" style={{ marginTop: 10 }}>
        <Nav.Link as={Link} to="/" exact>
          Home
        </Nav.Link>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "max-content",
            gap: 10,
          }}
        >
          <Nav.Link as={Link} to="/about">
            ←About
          </Nav.Link>
          <Nav.Link as={Link} to="/service">
            Services→
          </Nav.Link>
        </div>
      </div>
    </div>
  );
};

export default Product;
